<template>
  <div class="p-4">
    <p class="text-center uppercase tracking-wider text-3xl">Area Admin</p>
    <p class="text-center text-2xl">Selamat Datang</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
